import { createSignal, Match, Show, Switch } from "solid-js";
import { useStore } from "@nanostores/solid";
import { $itemMenuItem, $itemMenuShow } from "../stores/itemMenu.ts";
import { dispatchForceSyncEvent } from "../assets/js/util.ts";
import dayjs from "dayjs";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import CATEGORY from "../assets/js/category.ts";
import { debounce, startCase } from "es-toolkit";

import ENABLED_CATEGORIES from "../assets/js/enabledCategories.ts";
import { localItems } from "../assets/js/pouchDB.ts";
import { generateItemID } from "../assets/js/itemService.ts";
import StoreIcons from "./storeIcons.tsx";
import ImageService from "../assets/js/imageService.js";
// codecs
import resize from "@jsquash/resize";
import { decode as decodeJpeg } from "@jsquash/jpeg";
import { decode as decodePng } from "@jsquash/png";
import { decode as decodeWebp } from "@jsquash/webp";
import { decode as decodeAvif, encode as encodeAvif } from "@jsquash/avif";
import { ItemSchema } from "../../@types/schemas/trolley.js";
import { marked } from "marked";

dayjs.extend(relativeTimePlugin);

const firstEnabledCategoryName = startCase(ENABLED_CATEGORIES[0].name);

export default function ItemMenu() {
	// refs
	let elementItemMenu;
	// global
	const itemMenuItem = useStore($itemMenuItem);
	const itemMenuShow = useStore($itemMenuShow);
	// local
	const [loading, setLoading] = createSignal(false);
	const [isEditMode, setIsEditMode] = createSignal(false);

	const cleanup = () => {
		setIsEditMode(false);

		elementItemMenu.addEventListener(
			"animationend",
			() => {
				$itemMenuItem.set(null);
				$itemMenuShow.set(false);
			},
			{ once: true },
		);

		elementItemMenu.classList.remove("animate-slit-in-horizontal");

		requestAnimationFrame(() => {
			requestAnimationFrame(() => {
				elementItemMenu.classList.add(
					"animate-slit-in-horizontal-reverse",
				);
			});
		});
	};

	async function updateStores(newStore: string) {
		let updatedStores = [...$itemMenuItem.get().stores];

		if (updatedStores.includes(newStore)) {
			// Remove the store from the array
			updatedStores = updatedStores.filter((store) => store !== newStore);
		} else {
			// Add the new store to the array
			updatedStores.push(newStore);
		}

		await localItems.upsert(itemMenuItem()?._id, (existingItem) => {
			existingItem.stores = updatedStores;

			return ItemSchema.parse(existingItem);
		});

		$itemMenuItem.set({
			...$itemMenuItem.get(),
			stores: updatedStores,
		});

		dispatchForceSyncEvent();
	}

	const updateItem = async () => {
		setLoading(true);

		dispatchForceSyncEvent();
		cleanup();
		setLoading(false);
	};

	const updateItemImage = async () => {
		const imageUrl = prompt("Enter image URL", "");

		if (imageUrl) {
			try {
				setLoading(true);
				const imageBlob = await fetch(imageUrl).then((r) => r.blob());
				let imageData;

				switch (imageBlob.type) {
					case "image/avif":
						imageData = await decodeAvif(
							await imageBlob.arrayBuffer(),
						);
						break;
					case "image/webp":
						imageData = await decodeWebp(
							await imageBlob.arrayBuffer(),
						);
						break;
					case "image/png":
						imageData = await decodePng(
							await imageBlob.arrayBuffer(),
						);
						break;
					case "image/jpeg":
						imageData = await decodeJpeg(
							await imageBlob.arrayBuffer(),
						);
						break;
					default:
						throw new Error("Unsupported image type");
				}

				const maxImageWidth = 200;

				if (imageData.width > maxImageWidth) {
					imageData = await resize(imageData, {
						fitMethod: "contain",
						width: maxImageWidth,
						height: maxImageWidth,
					});
				}

				const encodedImageBuffer = await encodeAvif(imageData, {
					cqLevel: 10,
					speed: 5,
				});

				// convert the image buffer to a blob
				const resizedImageBlob = new Blob([encodedImageBuffer], {
					type: "image/avif",
				});

				// in case we made edits to the item elsewhere
				const savedItemMeta = await localItems.put(
					ItemSchema.parse($itemMenuItem.get()),
				);

				await localItems.putAttachment(
					savedItemMeta.id,
					"image",
					savedItemMeta.rev,
					resizedImageBlob,
					resizedImageBlob.type,
				);

				dispatchForceSyncEvent();
			} catch (error) {
				alert("Failed to set image.");
				console.error(error);
			} finally {
				cleanup();
				setLoading(false);
			}
		}
	};

	const addToList = async () => {
		const { name, added_by } = itemMenuItem();

		const itemID = generateItemID(name);

		let item = {};

		try {
			// overwrite item if it exists
			item = await localItems.get(itemID);
			item.category = ENABLED_CATEGORIES[0].name; // todo: make this more flexible
		} catch (error) {
			if (error.status === 404) {
				const imageBlob = ImageService.get(name);

				item = {
					_id: generateItemID(name),
					archived: false,
					added_by,
					added_on: new Date().toISOString(),
					category: ENABLED_CATEGORIES[0].name, // todo: make this more flexible
					stores: [],
					name,
					amount: 1,
					_attachments: {
						image: {
							content_type: imageBlob.type,
							data: imageBlob,
						},
					},
				};
			} else {
				console.log("error ", error);
			}
		}

		const addedItemMetadata = await localItems.put(ItemSchema.parse(item));
		const addedItem = await localItems.get(addedItemMetadata.id, {
			attachments: true,
			binary: true,
		});

		// add an image attachment if one does not exist
		if (!addedItem?._attachments?.image?.data) {
			const imageBlob = ImageService.get(name);
			await localItems.putAttachment(
				addedItem._id,
				"image",
				addedItem._rev,
				imageBlob,
				imageBlob.type,
			);
		}

		dispatchForceSyncEvent();

		cleanup();
	};

	const removeFromTrolley = async () => {
		setLoading(true);
		const itemToDelete = await localItems.get(itemMenuItem()?._id);

		// soft delete items
		await localItems.put(
			ItemSchema.parse({
				...itemToDelete,
				archived: true,
				// remove urgent and unsure symbols when archiving
				name: itemToDelete.name.replace(/([?!])/gm, ""),
				description: "", // clear description on archive
			}),
		);

		dispatchForceSyncEvent();

		setLoading(false);
		navigator?.vibrate(100);
		cleanup();
	};

	return (
		<Show when={itemMenuShow()}>
			<div
				ref={elementItemMenu}
				class="animate-slit-in-horizontal top-0 left-0 z-50 fixed w-[100vw] h-[100dvh] overscroll-contain grid grid-rows-[50%_auto]"
			>
				<div
					class="animate-fade-in w-[100vw] h-[100dvh] bg-black opacity-60 cursor-pointer"
					onClick={cleanup}
				/>
				<div class="absolute w-[100vw] bottom-0 left-0 grid grid-rows-[auto_auto] border border-[0.2rem] border-secondary bg-main-menu">
					<div class="flex flex-col gap-4 justify-center items-center relative text-center select-none text-2xl pt-2 pl-8 pr-8">
						<Switch fallback={<div>No image</div>}>
							<Match when={loading() === true}>
								<svg
									width="2rem"
									class="animate-spin"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z" />
								</svg>
							</Match>
							<Match when={loading() === false}>
								<img
									class="bg-white w-[48px] h-[48px] cursor-pointer"
									width="48"
									height="48"
									alt={itemMenuItem()?.name + " image"}
									src={URL.createObjectURL(
										itemMenuItem()?._attachments?.image
											?.data ?? new Blob(),
									)}
									onLoad={(event) => {
										// release memory
										URL.revokeObjectURL(event.target.src);
									}}
									onClick={updateItemImage}
								/>
							</Match>
						</Switch>
						<input
							type="text"
							class="input text-center"
							value={itemMenuItem()?.name}
							onKeyUp={debounce(async ({ target }) => {
								await localItems.upsert(
									itemMenuItem()?._id,
									(existingItem) => {
										existingItem.name = target.value.trim();

										return ItemSchema.parse(existingItem);
									},
								);

								dispatchForceSyncEvent();
							}, 300)}
						/>
					</div>

					<div class="pt-4 pr-4 pb-8 pl-4">
						<div class="columns is-mobile">
							<div class="column">
								<div class="field">
									<label class="label has-text-centered">
										Description
										<span
											class="cursor-pointer ml-2"
											onClick={async () => {
												setIsEditMode(!isEditMode());

												const item =
													await localItems.get(
														itemMenuItem()?._id,
														{
															attachments: true,
															binary: true,
														},
													);

												$itemMenuItem.set(item);
											}}
										>
											<Switch>
												<Match when={!isEditMode()}>
													<svg
														class="inline"
														xmlns="http://www.w3.org/2000/svg"
														width="1rem"
														viewBox="0 0 512 512"
													>
														<path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
													</svg>
												</Match>
												<Match when={isEditMode()}>
													<svg
														class="inline"
														xmlns="http://www.w3.org/2000/svg"
														width="1rem"
														viewBox="0 0 448 512"
													>
														<path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
													</svg>
												</Match>
											</Switch>
										</span>
									</label>
									<div class="control">
										<Switch>
											<Match when={isEditMode()}>
												<textarea
													rows="3"
													class="textarea has-text-centered"
													value={
														itemMenuItem()
															?.description ?? ""
													}
													onInput={debounce(
														async ({ target }) => {
															await localItems.upsert(
																itemMenuItem()
																	?._id,
																(
																	existingItem,
																) => {
																	existingItem.description =
																		target.value.trim();

																	return ItemSchema.parse(
																		existingItem,
																	);
																},
															);

															dispatchForceSyncEvent();
														},
														300,
													)}
												/>
											</Match>
											<Match when={!isEditMode()}>
												<div
													class="prose flex flex-col items-center justify-center text-center break-all"
													innerHTML={marked.parseInline(
														itemMenuItem()
															?.description ?? "",
													)}
												/>
											</Match>
										</Switch>
									</div>
								</div>
							</div>
						</div>

						<div class="columns is-mobile">
							<div class="column">
								<div class="field">
									<label class="label has-text-centered">
										Amount (#)
									</label>
									<div class="control">
										<input
											type="number"
											class="input has-text-centered"
											value={itemMenuItem()?.amount}
											onFocus={(event) =>
												event.target.select()
											}
											onInput={debounce(
												async ({ target }) => {
													await localItems.upsert(
														itemMenuItem()?._id,
														(existingItem) => {
															existingItem.amount =
																parseInt(
																	target.value,
																);

															return ItemSchema.parse(
																existingItem,
															);
														},
													);

													dispatchForceSyncEvent();
												},
												300,
											)}
											min="1"
										/>
									</div>
								</div>
							</div>
						</div>

						<StoreIcons
							stores={itemMenuItem()?.stores}
							onChange={(newCategory) =>
								updateStores(newCategory)
							}
						/>

						<Show
							when={
								itemMenuItem()?.category === CATEGORY.INVENTORY
							}
						>
							<div class="columns">
								<div class="column">
									<button
										type="button"
										class="button w-full p-2 rounded-sm select-none text-center border border-2 border-gray"
										onClick={addToList}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16px"
											viewBox="0 0 448 512"
											class="button-move"
										>
											<path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
										</svg>
										<span>
											&nbsp;Add to&nbsp;
											{firstEnabledCategoryName} list
										</span>
									</button>
								</div>
							</div>
						</Show>

						<div class="columns">
							<div class="column">
								<div class="is-size-6 is-italic has-text-weight-light added text-center">
									<Show when={itemMenuItem()?.edited_by}>
										<div>
											<b>edited by</b>
											<span class="pl-1 pr-1">
												{itemMenuItem()?.edited_by}
											</span>
											<span>
												{formatTimeStamp(
													itemMenuItem()?.edited_on,
												)}
											</span>
										</div>
									</Show>
									<div>
										<b>added by</b>
										<span class="pl-1 pr-1">
											{itemMenuItem()?.added_by}
										</span>
										<span>
											{formatTimeStamp(
												itemMenuItem()?.added_on,
											)}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div class="columns is-mobile">
							<div class="column">
								<button
									type="button"
									classList={{
										"button w-full p-2 rounded-sm select-none text-center border border-2 border-red-200 bg-red-50":
											true,
										"is-loading": loading(),
									}}
									onClick={removeFromTrolley}
								>
									<Show when={!loading()}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16px"
											viewBox="0 0 448 512"
											class="fill-red-500"
										>
											<path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
										</svg>
									</Show>
									<span>&nbsp; Delete</span>
								</button>
							</div>
							<div class="column">
								<button
									type="button"
									classList={{
										"button w-full p-2 rounded-sm select-none text-center border border-2 border-green-200 bg-green-50":
											true,
										"is-loading": loading(),
									}}
									onClick={updateItem}
								>
									<Show when={!loading()}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16px"
											viewBox="0 0 448 512"
											class="fill-green-500"
										>
											<path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
										</svg>
									</Show>
									<span>&nbsp; Close</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Show>
	);
}

/**
 * Module Methods
 */

function formatTimeStamp(timeStamp) {
	return dayjs.utc(timeStamp).local().fromNow();
}
